<template>
  <nav class="menu-top">
    <div class="mobile-burger" @click="openMenuHeader">
      <div class="mobile-burger__btn">
        <span></span>
      </div>
    </div>
    <GarlandVue />
    <menu-top-list
      :class="{ hideMobile: !menuOpen }"
      :openMH="openMenuHeader"
    />
    <mobile-link />
    <!-- <social-links /> -->
  </nav>
</template>

<script>
import MenuTopList from "./Navmenu/MenuTopList";
import SocialLinks from "./Header/SocialLinks";
import MobileLink from "./Header/MobileLink";
import GarlandVue from "./garland/Garland";

export default {
  components: {
    MenuTopList,
    SocialLinks,
    MobileLink,
    GarlandVue,
  },

  data() {
    return {
      menuOpen: false,
    };
  },

  methods: {
    openMenuHeader() {
      this.menuOpen = !this.menuOpen;
    },
  },
};
</script>

<style lang="scss">
.menu-top {
  margin: auto;
  width: 100%;
  background-color: #1c7fe2;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 160;
  -webkit-box-shadow: 0px 4px 2px 0px rgba(255, 255, 255, 0.75);
  -moz-box-shadow: 0px 4px 2px 0px rgba(255, 255, 255, 0.75);
  box-shadow: 0px 4px 2px 0px rgba(255, 255, 255, 0.75);

  @media screen and (max-width: 1014px) {
    box-shadow: none;
    width: 100%;
  }

  @media screen and (max-width: 990px) {
    flex-direction: row-reverse;
  }
}
// бургер
.mobile-burger {
  color: #fff;
  display: none;
  @media screen and (max-width: 990px) {
    display: block;
    margin: 12px;
  }
}

.mobile-burger__btn {
  display: flex; /* используем flex для центрирования содержимого */
  align-items: center; /* центрируем содержимое кнопки */
  width: 26px;
  height: 26px;
  cursor: pointer;
  z-index: 1;
}

.mobile-burger__btn > span,
.mobile-burger__btn > span::before,
.mobile-burger__btn > span::after {
  display: block;
  position: absolute;
  width: 26px;
  height: 2px;
  background-color: #fff;
}
.mobile-burger__btn > span::before {
  content: "";
  top: -8px;
}
.mobile-burger__btn > span::after {
  content: "";
  top: 8px;
}

.mobile-burger__toggle {
  opacity: 0;
}

.hideMobile {
  @media screen and (max-width: 990px) {
    display: none;
  }
}
</style>

